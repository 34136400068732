@charset "UTF-8";

// Default Variables

// $slick-font-path: "../fonts/slick/" !default;
$slick-font-family: "fontAwesome" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: white !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
// $slick-dot-character: "\f111" !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .3 !default;


//@function slick-image-url($url) {
    // @if function-exists(image-url) {
    //     @return image-url($url);
    // }
    // @else {
        //@return url($slick-loader-path + $url);
    // }
//}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("../images/charte/loader.svg") center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -15px;
    padding: 0;
    border: none;
    outline: none;
    
    &:hover,
    &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}

.slick-prev:before,
.slick-next:before {
    font-family: $slick-font-family;
    font-size: 30px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -35px;
    [dir="rtl"] & {
        left: auto;
        right: -35px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -35px;
    [dir="rtl"] & {
        left: -35px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

.slick-slider {
    margin-bottom: 5rem;
}

.slick-slide {
    text-align: center;
    
    img {
        display: inline-block;
        margin: auto;
    }
}

/* Dots */

.slick-dots {
    position: absolute;
    bottom: -3.5rem;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    
    li {
        position: relative;
        display: inline-block;
        background: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        margin: 0 7px;
        padding: 0;
        cursor: pointer;
        transition: width ease .3s, opacity ease .3s;
        
        button {
            display: none;
        }

        &.slick-active {
            width: 30px;
            opacity: $slick-opacity-default;
        }
    }
}